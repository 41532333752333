@import "../../global.scss";

.intro {
  background-color: white;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    overflow: hidden;
    word-wrap: break-word;

    @include mobile4k {
      margin-top: 5%;
      margin-left: 30%;
    }

    @include mobile2k {
      margin-top: 10%;
      margin-left: 30%;
    }

    @include default {
      margin-left: 30%;
      margin-top: 8%;
    }
    @include mobile {
      margin-top: 30%;
      margin-left: 20%;
    }

    .wrapper {
      width: 100%;
      height: 100%;

      h2 {
        font-size: 100px;

        @include mobile4k {
          font-size: 150px;
        }

        @include mobile2k {
          font-size: 100px;
        }

        @include default {
          font-size: 100px;
        }
        @include mobile {
          font-size: 50px;
        }
      }

      h3 {
        font-size: 100px;
        @include mobile4k {
          font-size: 150px;
        }
        @include mobile2k {
          font-size: 100px;
        }
        @include default {
          font-size: 100px;
        }
        @include mobile {
          font-size: 50px;
        }
        span {
          font-size: inherit;
          color: darkblue;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }

    a {
      position: absolute;
      bottom: 10px;
      right: 50%;

      .down {
        color: $mainColor;
        animation: arrowBlink 2s infinite;
        font-size: 35px;

        @keyframes arrowBlink {
          100% {
            opacity: 0;
          }
        }
      }
    }
  }
}
