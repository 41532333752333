@import "../../global.scss";

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;

    @include mobile {
      font-size: 30px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px 0;
    }
  }

  .container {
    width: 90%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      display: block;
      width: auto;
      height: 80%;
    }

    .item {
      width: 35%;
      height: 45%;
      border-radius: 20px;
      border: 1px solid rgb(211, 211, 211);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;

      overflow: hidden;

      @include mobile4k {
        width: 35%;
        height: 45%;
      }

      @include default {
        width: 400px;
        height: 300px;
      }

      @include mobile2 {
        width: 280px;
        height: 180px;
      }

      @include mobile {
        margin-top: 25px;
        width: 320px;
        height: 100px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
        opacity: 0;
      }

      h4 {
        top: 55%;
        position: absolute;
        font-size: 15px;
        opacity: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }

      a {
        text-decoration: none;
        color: inherit;
      }
      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
        h3,
        h4 {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}
