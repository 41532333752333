@import "../../global.scss";

.aboutMe {
  background-color: white;
  display: flex;
  align-items: center;

  @include mobile2 {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }
}

.aboutMeLeft {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  right: 10%;

  @include mobile2 {
    right: 0;
  }
}

.aboutMeRight {
  flex: 1;
  position: relative;
  bottom: 5%;
}

.card {
  width: 100%;
  height: 60vh;
  border-radius: 30px;
  overflow: hidden;
  position: relative;

  @include mobile2 {
    height: 35vh;
    width: 90%;
    margin-bottom: 50px;
    border-radius: 30px;
    margin-top: 10px;
  }
}

.card.bg {
  @include mobile2 {
    display: none;
  }
}

.aboutImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.award-img {
  width: 100px;
  height: 100px;

  @include mobile4k {
    width: 130px;
    height: 130px;
  }

  @include mobile2k {
    width: 100px;
    height: 100px;
  }

  @include mobile {
    width: 50px;
    height: 50px;
  }
}

.aboutMeTitle {
  font-weight: 400;

  @include mobile4k {
    font-size: 50px;
  }

  @include mobile2k {
    font-size: 40px;
  }

  @include default {
    font-size: 40px;
  }

  @include mobile {
    font-size: 25px;
  }
}

.subtitle {
  margin: 20px 0px;

  @include mobile4k {
    font-size: 40px;
  }
  @include mobile2k {
    font-size: 30px;
  }

  @include default {
    font-size: 30px;
  }
  @include mobile2 {
    font-size: 25px;
    margin: 10px 0px;
  }
  @include mobile {
    font-size: 18px;
  }
}
.description {
  width: 70%;
  font-weight: 300;

  @include mobile4k {
    font-size: 30px;
  }
  @include mobile2k {
    font-size: 23px;
  }

  @include default {
    font-size: 25px;
  }

  @include mobile2 {
    align-items: center;
    margin: auto;

    font-size: 17px;
  }

  @include mobile {
    align-items: center;
    margin: auto;
    font-size: 12px;
  }
}

.description2 {
  width: 70%;
  font-weight: 300;

  @include mobile4k {
    font-size: 25px;
  }
  @include mobile2k {
    font-size: 20px;
  }

  @include default {
    font-size: 20px;
  }

  @include mobile2 {
    align-items: center;
    margin: auto;

    font-size: 15px;
  }

  @include mobile {
    align-items: center;
    margin: auto;
    font-size: 10px;
  }
}

.description3 {
  width: 70%;
  font-weight: 300;

  @include mobile4k {
    font-size: 25px;
  }
  @include mobile2k {
    font-size: 20px;
  }

  @include default {
    font-size: 20px;
  }

  @include mobile2 {
    align-items: center;
    margin: auto;

    font-size: 15px;
  }

  @include mobile {
    align-items: center;
    margin: auto;
    font-size: 10px;
  }
}

.languages {
  width: 70%;
  font-weight: 300;

  @include mobile4k {
    font-size: 25px;
  }
  @include mobile2k {
    font-size: 20px;
  }

  @include default {
    font-size: 20px;
  }

  @include mobile2 {
    align-items: center;
    margin: auto;

    font-size: 15px;
  }

  @include mobile {
    align-items: center;
    margin: auto;
    font-size: 10px;
  }
}

.award {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;

  @include mobile4k {
    margin-top: 5%;
  }
}

.award img {
  @include mobile2 {
    flex: 1;
    margin-left: 20%;
  }

  @include mobile {
    flex: 1;
    margin-left: 30px;
    display: none;
  }
}

.award-text {
  width: 80%;

  @include mobile4k {
    font-size: 30px;
  }

  @include mobile2k {
    font-size: 23px;
  }
  @include default {
    font-size: 25px;
  }

  @include mobile2 {
    flex: 2;
    font-size: 20px;
    padding-right: 30%;
  }

  @include mobile {
    font-size: 12px;
    padding-right: 30%;
    display: none;
  }
}

.award-title {
  font-weight: 900;
  color: #555;
  margin-bottom: 10px;
  @include mobile {
    margin-bottom: 10px;
    display: none;
  }
}
