@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;

  .left {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 50%;
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;

      @include mobile4k {
        font-size: 50px;
      }
      @include mobile2k {
        font-size: 40px;
      }

      @include default {
        font-size: 40px;
      }
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-top: 50px;
      @include mobile {
        width: 100%;
      }
      input {
        width: 300px;
        height: 30px;
        font-size: 14px;
        border: none;
        border-bottom: 1px solid black;
        padding-left: 10px;

        @include mobile4k {
          font-size: 25px;
          width: 600px;
          height: 40px;
        }

        @include mobile2k {
          font-size: 25px;
          width: 500px;
          height: 40px;
        }

        @include default {
          width: 300px;
          height: 30px;
          font-size: 14px;
        }

        @include mobile {
          padding-left: 10px;
          &:focus {
            outline: none;
          }
        }
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;
        outline: none;

        @include mobile4k {
          font-size: 25px;
          width: 600px;
          height: 300px;
        }

        @include mobile2k {
          font-size: 25px;
        }

        @include mobile2k {
          width: 500px;
          height: 200px;
        }
        @include default {
          width: 300px;
          height: 200px;
          font-size: 14px;
        }

        @include mobile {
          padding-left: 10px;
          &:focus {
            outline: none;
          }
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: darkblue;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        @include mobile4k {
          font-size: 35px;
          width: 350px;
          height: 90px;
        }

        @include mobile2k {
          font-size: 15px;
          width: 200px;
          height: 50px;
        }
        @include default {
          width: 150px;
          height: 30px;
          font-size: 15px;
        }
      }
      span {
        color: green;
      }

      span.error {
        color: red;
      }
    }
  }
}
